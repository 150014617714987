import React, { useState, useEffect } from 'react'
import { useTable } from 'react-table'
import { useDispatch } from 'react-redux'
import {
  getBankByName,
  getBankList
} from '../../../../../Services/BranchOnboarding'
import { branchPagination } from '../../../../../Services/Pagination'
import Loader from '../../../../CommonComponents/Loader'
import { showFailed } from '../../../../CommonComponents/SweetAlert'
import {
  addBranchReducer,
  updateBranchReducer
} from '../../../../../Reducer/BranchReducer'
import { Button1 } from '../../../../CommonComponents/Button'
import BranchOnboarding from './BranchOnboarding'
import DataTable from '../../../../CommonComponents/DataTable'
import Pagination from '../../../../CommonComponents/Pagination'

const BranchTable = () => {
  const [errors, setError] = useState()
  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [operation, setOperation] = useState('add')
  const [isLoading, setIsLoading] = useState(false)
  const [isPreviewOpen, setPreviewOpen] = useState(false)
  const [fetchLoading, setFetchLoading] = useState(false)
  const [rangeStart, setRangeStart] = useState(new Date())
  const [rangeEnd, setRangeEnd] = useState(() => {
    const defaultEndDate = new Date()
    defaultEndDate.setDate(defaultEndDate.getDate())
    return defaultEndDate
  })
  const [pagination, setPagination] = useState(null)
  const [branchId, setBranchId] = useState()
  const [options, setOptions] = useState([])
  const [isBankName, setBankName] = useState()

  const formData = {
    bankName: isBankName || '',
    branchId: '',
    ifsc: '',
    bankMobile: '',
    authMobileNumber: '',
    authEmail: '',
    authDesignation: '',
    authName: '',
    pinCode: '',
    state: '',
    city: '',
    address: '',
    gstNumber: ''
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBankList()
        setOptions(data)
        console.log('Roles Data is here:---', data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  const fetchBranchesData = async bankName => {
    setError(null)
    setFetchLoading(true)
    setSubmitLoading(true)
    setIsLoading(true)
    try {
      const data = await getBankByName(bankName)
      console.log('Branches Data : ', data)
      setUsers(data.docs)
      setPagination({
        totalPages: data.totalPages,
        page: data.page,
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage
      })
      dispatch({ type: 'BRANCH', payload: data })
      setIsLoading(false)
      setSubmitLoading(false)
    } catch (error) {
      setUsers([]);
      setSubmitLoading(false)
      console.error('Error fetching data:', error)
    }
  }

  const goToNextPage = async () => {
    const nextPageIndex = pagination.page + 1
    if (nextPageIndex <= pagination.totalPages) {
      try {
        const data = await branchPagination(nextPageIndex)
        setUsers(data.docs)
        setPagination(prevState => ({
          ...prevState,
          page: nextPageIndex,
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage
        }))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }

  const goToPrevPage = async () => {
    const prevPageIndex = pagination.page - 1 // Use pagination state
    if (prevPageIndex >= 1) {
      // Fix comparison
      try {
        const data = await branchPagination(prevPageIndex) // Pass prevPageIndex
        setUsers(data.docs)
        setPagination(prevState => ({
          ...prevState,
          page: prevPageIndex, // Update page in pagination state
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage
        }))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }

  const handleEdit = _id => {
    console.log('Edit Action triggerd', _id)
    setOperation('update')
    setPreviewOpen(true)
    setBranchId(_id)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Branch Name',
        accessor: 'name'
      },
      {
        Header: 'Branch User ID',
        accessor: 'b_id'
      },
      {
        Header: 'Manager Name',
        accessor: 'manager.name'
      },
      {
        Header: 'IFSC Code',
        accessor: 'ifsc'
      },
      {
        Header: 'Contact Number',
        accessor: 'phone_number'
      },
      {
        Header: '',
        accessor: 'edit',
        Cell: ({ row }) => (
          <button
            className='border bg-bgclr text-white px-2'
            onClick={() => handleEdit(row.original._id)}
          >
            Edit
          </button>
        )
      }
    ],
    []
  )

  const data = React.useMemo(() => users)

  console.log('Print the Data => ', data)

  const handleClose = async () => {
    setPreviewOpen(false)
  }

  const handleDropdownChange = async event => {
    if (event.target.value !== 'default') {
      await fetchBranchesData(event.target.value)
      setBankName(event.target.value)
    } else {
      setUsers([])
      setBankName('')
    }
  }

  const handleAddBranch = () => {
    setOperation('Add')
    setPreviewOpen(true)
  }

  const handleSubmit = values => {
    try {
      dispatch(addBranchReducer(values))
      setPreviewOpen(false)
      setSubmitLoading(true)
      setTimeout(async () => {
        fetchBranchesData(isBankName);
      }, 2000)
    } catch (error) {
      console.error('Error adding user:', error)
      setSubmitLoading(false)
      showFailed('Failed to add user. Please try again later.')
    }
  }

  const handleUpdate = values => {
    console.log('Updated User -> ', values)
    setIsLoadingUpdate(true)
    dispatch(updateBranchReducer({ formData: values, branchId: branchId }))
    handleClose()
    setTimeout(() => {
      fetchBranchesData(isBankName)
      setIsLoadingUpdate(false)
    }, 500)
  }

  return (
    <>
      <div className='flex justify-end bg-bgclr4 min-h-screen py-10'>
        <div className='rounded-md w-3/4 ml-32'>
          <div className='flex'>
            <h1 className='text-3xl font-bold'>Branch Onboarding</h1>
          </div>
          <div className='flex'>
            <div className='mt-9'>
              <Button1 label='+Add Branch' onClick={handleAddBranch} />
            </div>
            <div className='mr-3 mt-4 ml-28 p-4'>
              <label htmlFor='dropdown' className='mr-3'>
                Select a Bank:
              </label>
              <select
                id='bankName'
                name='Bank Name'
                // value={selectedOption}
                onChange={handleDropdownChange}
                className='input-style border border-gray-300 w-60 rounded-md'
              >
                <option value='default'>Select Bank Name</option>
                {options?.map(option => (
                  <option key={option?._id} value={option?._id}>
                    {option?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            {!errors ? (
              <div className='w-[975px] mt-6 mb-20'>
                {isSubmitLoading ? (
                  <div className='-mt-36 -ml-32'>
                    <Loader />
                  </div>
                ) : (
                  <div>
                    <div className='pt-3 overflow-x-auto mb-10'>
                      <DataTable columns={columns} data={data} />
                    </div>
                    <Pagination
                      // hasPrevPage={pagination.hasPrevPage}
                      // hasNextPage={pagination.hasNextPage}
                      goToPrevPage={goToPrevPage}
                      goToNextPage={goToNextPage}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className='flex ml-80 mt-5'>
                <h1 className='font-bold text-3xl text-gray-500'>{errors}</h1>
              </div>
            )}
          </div>
        </div>
      </div>

      {isPreviewOpen && (
        <BranchOnboarding
          isPreviewOpen={isPreviewOpen}
          onClose={() => setPreviewOpen(false)}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          formData={formData}
          isLoadingUpdate={isLoadingUpdate}
          branchId={branchId}
          operation={operation}
          options={options}
          isBankName={isBankName}
        />
      )}
    </>
  )
}

export default BranchTable
